import React from 'react';
import Layout from '../containers/Layout';
import ContactPage from '../containers/ContactPage';

const TermsAndConditionsPage: React.FC = () => (
  <Layout title="Solvana | Contact Us">
    <ContactPage />
  </Layout>
);

export default TermsAndConditionsPage;
