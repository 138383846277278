import React from 'react';
import { Container, Link, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import Quote from '../../components/Quote';

const ContactPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.innerContainer}>
          <Typography color="primary" variant="h1">
            Solar Yoda, at your service, we are.
          </Typography>

          <Typography className={classes.paragraph} variant="body1">
            Here at Solvana, you&apos;ll have the tools you need to transition to solar energy with confidence, at your
            pace. And we&apos;re here for you every step of the way as your Solar Yoda.
          </Typography>
          <Typography className={classes.paragraph} variant="body1">
            We&apos;ll guide you through this entire process and help you avoid common mistakes when transitioning to
            solar using our Solar Praxeum™ course.
          </Typography>
          <Typography className={classes.paragraph} variant="body1">
            We&apos;re happy you&apos;re here and look forward to supporting you through the lifetime of your solar
            energy journey.
          </Typography>
        </div>

        <div className={classes.contactContainer}>
          <Typography variant="h5">Let&apos;s Chat!</Typography>
          <Typography variant="subtitle1">
            Email: <b>hi@solvana.io</b>
          </Typography>
          Phone: <Link href="tel:888-205-2462">888-205-2462</Link>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Quote
          withBG
          quote="I did my research since it was my first solar project and I felt (Solvana) had the most knowledge. I will be using them on my other properties."
          authorName="Mike B."
          authorLocation="New Jersey"
        />
      </div>
    </Container>
  );
};

export default ContactPage;
