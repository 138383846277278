import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors, linearPrimaryGradient } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(0, 3, 12),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: theme.spacing(0, 2, 8),
      },
    },
    leftContainer: {
      width: '50%',
      padding: theme.spacing('3%', 0, 0, '5%'),
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
      },
    },
    contactContainer: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : Colors.GreyFive,
      padding: theme.spacing(3),
      margin: theme.spacing(4, 4, 8, 0),
      borderRadius: theme.spacing(1),
      display: '',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(4, 'auto', 8),
        maxWidth: '500px',
      },
    },
    paragraph: {
      margin: theme.spacing(3, 0, 0),
      paddingRight: theme.spacing(8),
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    innerContainer: {
      paddingRight: theme.spacing(3),
      '& h1': {
        fontSize: '76px',
        lineHeight: '76px',
        [theme.breakpoints.down('md')]: {
          fontSize: '60px',
          lineHeight: '60px',
          width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '50px',
          lineHeight: '50px',
        },
        background: linearPrimaryGradient,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      },
      '& h2': {
        fontSize: '38px',
        lineHeight: '38px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '600px',
        margin: '0 auto',
      },
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '7vh',
    },
    getStartedButton: {
      marginTop: theme.spacing(3),
      maxWidth: '280px',
      color: '#fff',
      [theme.breakpoints.down('md')]: {
        maxWidth: '220px',
      },
    },
    rightContainer: {
      width: '50%',
      padding: theme.spacing(14, 0, 0),
      '& img': {
        padding: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 0,
        '& img': {
          marginTop: 0,
        },
      },
    },
  }),
);
